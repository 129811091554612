import { lazy, Suspense } from "react";
import "./index.css";
import React from "react";
import AnimatedCursor from "react-animated-cursor";

const Hero = lazy(() => import("./components/Hero"));
const Project = lazy(() => import("./components/Project"));
const Skill = lazy(() => import("./components/Skill"));
const Contact = lazy(() => import("./components/Contact"));
const Timeline = lazy(() => import("./components/Timeline"));
const About = lazy(() => import("./components/Aboutme"));

function App() {
  const imageLoader =
    "https://res.cloudinary.com/deb6r2y8g/image/upload/v1660623664/Rocket_500_500_px_mqh8oj.gif";
  const [isVisible, setVisible] = React.useState(false);

  setTimeout(function () {
    setVisible(true)
  }, 2000)

  return (
    <Suspense
      fallback={
        <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube"></div>
          <div className="sk-cube2 sk-cube"></div>
          <div className="sk-cube4 sk-cube"></div>
          <div className="sk-cube3 sk-cube"></div>
        </div>
      }
    >
      {!isVisible && (
        <div className="loading-div">
          <div className="inner-loading-div">
            <img src={imageLoader}></img>
            <p>Loading...</p>
          </div>
        </div>
      )}
      <div className="App">
        <AnimatedCursor
          innerSize={15}
          outerSize={20}
          color="57,227,156"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
        />
        {isVisible && <Hero />}
        {isVisible && <About />}
        {!isVisible && <Hero />}
        {!isVisible && <About />}
      </div>
      <div className="max-w-screen-xl mx-auto">
        <Skill />
        <Timeline />
        <Project />
      </div>
      <Contact />
    </Suspense>
  );
}
export default App;
